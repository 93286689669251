<template>
  <v-container>
    <v-row class="mt-15"></v-row>
    <v-row class="mt-15"></v-row>
    <v-row class="mt-15"></v-row>
    <v-row class="mt-15"></v-row>
    <v-row class="mt-15"></v-row>
    <v-row class="mt-15"></v-row>
    <v-row
      v-if="!isLIFF"
      justify="center">
      <v-btn
        color="#1977F2"
        class="font-weight-bold ma-2 py-6 px-6 white--text"
        @click="loginFacebook()"
      >
        <v-icon
          class="mr-4"
          left
          dark
          large
        >
          mdi-facebook
        </v-icon>
        Log in With Facebook
      </v-btn>
      <!-- <v-btn
        :loading="loading"
        :disabled="loading"
        color="#06C755"
        class="ma-2 white--text"
        @click="loginLine()"
      >
        Login with
        <v-icon
          right
          dark
          large
        >
          fa-brands fa-line
        </v-icon>
      </v-btn> -->
    </v-row>
  </v-container>
</template>

<script>
import liff from '@line/liff'
import firebase from '@/plugins/firebase'
import { mapActions } from 'vuex'

// import axios from 'axios'
export default {
  name: 'Home',
  data: () => ({
    facebookId: '',
    loading: false,
    isLIFF: true
  }),
  async mounted () {
    await liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
    if (liff.isInClient()) {
      this.isLIFF = true
      this.$router.push('/liff/booking')
    } else {
      this.isLIFF = false
    }
  },
  methods: {
    ...mapActions({
      setFacebookId: 'webExternal/setFacebookId'
    }),
    async loginFacebook () {
      try {
        this.loading = true
        const res = await firebase.signInWithPopup(firebase.auth, firebase.provider)
        this.facebookId = res.user.uid
        this.setFacebookId(this.facebookId)
        localStorage.setItem('facebookLogin', '{ status: true }')
        localStorage.setItem('facebookInfo', JSON.stringify(res.user.providerData[0]))
        this.$router.push('/booking')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    loginLine () {
      if (!liff.isLoggedIn()) {
        liff.login({ redirectUri: `${process.env.VUE_APP_FRONTEND_URL}/liff/booking` })
      }
    }
  }
}
</script>
<style scoped>
</style>
